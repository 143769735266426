import type { DatabaseEntity, DatabaseEnum } from '@voyage-lab/db';

const root = {
	api: '/api',
	account: '/account',
	dashboard: '/dashboard',
	workflow: '/workflow',
	conversation: '/conversation',
	knowledgebase: '/knowledgebase',
} as const;

export const client = {
	vyg: {
		root: '/vyg',
	},
	api: {
		root: '/api',
		auth: {
			root: `/api/auth` as const,
			signin(provider: string) {
				return `${client.api.auth.root}/signin/${provider}` as const;
			},
		},
		setup: (csrf?: boolean) => `${client.api.root}/setup` as const,
		integration: {
			root: '/api/integration' as const,
			load: (integrationId: DatabaseEntity['integrations']['id'], params?: Record<string, string>) =>
				`${client.api.integration.root}/${integrationId}/load${params ? `?${new URLSearchParams(params).toString()}` : ''}` as const,
			auth: (integrationId: DatabaseEntity['integrations']['id'], params?: Record<string, string>) =>
				`${client.api.integration.root}/${integrationId}/auth${params ? `?${new URLSearchParams(params).toString()}` : ''}` as const,
		},
	},
	unauthorized: `/unauthorized` as const,
	account: {
		root: '/account',
		selection: () => `${client.account.root}/selection` as const,
		finalized: {
			root: () => `${client.account.root}/finalized` as const,
		},
		signup: {
			root: () => `${client.account.root}/signup` as const,
			info: () => `${client.account.signup.root()}/info` as const,
			password: () => `${client.account.signup.root()}/password` as const,
			shopify: () => `${client.account.signup.root()}/shopify` as const,
		},
		discount: () => `${client.account.root}/discount` as const,
		setup: () => `${client.account.root}/setup` as const,
		guide: () => `${client.account.root}/guide` as const,
		shopify: {
			root: `${root.account}/shopify` as const,
			refresh: (integrationId: string, refreshComplete?: boolean) => ({
				root:
					`${client.account.shopify.root}` +
					'?integration_id=' +
					integrationId +
					(refreshComplete ? '&refresh_complete=true' : ''),
			}),
		},
	},
	dashboard: {
		root: '/dashboard',
	},
	auth: {
		root: '/auth',
		redirect: (params?: Record<string, string>) =>
			`${client.auth.root}/redirect${params ? `?${new URLSearchParams(params).toString()}` : ''}` as const,
		setup: () => `${client.auth.root}/setup` as const,
		login: (params?: Record<string, string>) =>
			`${client.auth.root}/login${params ? `?${new URLSearchParams(params).toString()}` : ''}` as const,
	},
	login: {
		root: '/auth/login',
	},
	workflow: {
		root: '/workflow',
		single: (id: string) => `${client.workflow.root}/${id}` as const,
		edit: (id: string, messageType?: 'draft_recovered' | 'draft_created') =>
			`${client.workflow.root}/${id}/edit${messageType ? `?message_type=${messageType}` : ''}` as const,
		create: (props?: { type: DatabaseEnum['t_workflows_type'] }) => {
			const url = `${client.workflow.root}/create` as const;
			if (props?.type) {
				const params = new URLSearchParams();
				params.set('type', props.type);
				return `${url}?${params.toString()}` as const;
			}
			return url;
		},
	},
	conversation: {
		root: '/conversation',
		dashboard: () => `${client.conversation.root}/dashboard` as const,
		list: () => `${client.conversation.root}/list` as const,
		single: (id: string) => `${client.conversation.root}/list?id=${id}` as const,
		feedback: () => `${client.conversation.root}/feedback` as const,
	},
	knowledgebase: {
		root: '/knowledgebase',
	},
	settings: {
		root: '/settings',
		members: () => `${client.settings.root}/members` as const,
		integrations: () => `${client.settings.root}/integrations` as const,
		account: () => `${client.settings.root}/account` as const,
		shop: () => `${client.settings.root}/shop` as const,
		billing: () => `${client.settings.root}/billing` as const,
		phone: () => `${client.settings.root}/phone` as const,
	},
} as const;
