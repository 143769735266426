'use client';

import { ErrorPage, Routes, type ErrorPageProps } from '@voyage-lab/web';
import { useRouter } from 'next/navigation';

export default function Error(props: ErrorPageProps) {
	const router = useRouter();

	function errorHandler(error: ErrorPageProps['error']) {
		console.debug('[Error Handler]', 'handling error for', error.cause);

		if (error.cause === 'unauthenticated') {
			console.debug('[Error Handler]', 'redirecting to login');
			router.replace(Routes.client.auth.login());
		}
	}

	return <ErrorPage {...props} onError={errorHandler} />;
}
