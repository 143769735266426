'use client';

import Link from 'next/link';
import { useRouter } from 'next/navigation';
import type { ErrorInfo } from 'react';
import { Component, useEffect } from 'react';

import type { AppError } from '@voyage-lab/core-common';
import { Button, Icon, Layout, Spacer, Text } from '@voyage-lab/ui';

import { ERROR } from '../../constants';
import { logError } from './action';

export type ErrorPageProps = {
	error: Error & { digest?: string } & AppError;
	reset: () => void;
	onError?: (error: Error & { digest?: string } & AppError) => void;
};
export function ErrorPage({ error, reset, onError, ...props }: ErrorPageProps) {
	const router = useRouter();
	const currentFiveSeconds = Math.floor(Date.now() / 5000);
	const randomMessage = messages[currentFiveSeconds % messages.length];

	const errorObj = {
		message: error.message,
		name: error.name,
		stack: error.stack,
		digest: error.digest,
		cause: error.cause,
	};

	const isClientError = !error.digest;
	const errorString = JSON.stringify(errorObj);

	useEffect(() => {
		// Log the error to an error reporting service
		console.debug('[Error Boundary]', errorObj);

		// Log the error to the server if it was a client error
		if (isClientError) {
			logError(errorObj);
		}
		onError?.(error);
	}, [errorString]);

	return (
		<Layout className="h-screen mx-auto grid place-items-center text-center px-8">
			<Layout gap={'4'} display={'flex'} direction={'col'} align={'center'}>
				<Icon name="Cone" className="w-20 h-20 mx-auto" />
				<Text>{String(randomMessage)}</Text>
				<Text variant={'secondary'}>{String(error.digest ? ERROR.GENERIC : error.message)}</Text>
				<Spacer vertical={8} />
				{isClientError ? (
					<Layout display={'flex'} gap={'2'} direction={'col'}>
						<Button size="lg" variant="outline" onClick={() => router.back()}>
							Go back
						</Button>
						<Link href="/">
							<Button size="sm" variant="link">
								Return home
							</Button>
						</Link>
					</Layout>
				) : (
					<Button onClick={reset} size="lg" variant="outline">
						Try Again
					</Button>
				)}
				{error.digest && (
					<Text size={'xs'} variant={'secondary'} className="underline underline-offset-4">
						{String(error.digest)}
					</Text>
				)}
			</Layout>
		</Layout>
	);
}

export class ErrorBoundary extends Component<
	{ fallback: React.ReactNode; children: React.ReactNode },
	{ hasError: boolean }
> {
	constructor(props: { fallback: React.ReactNode; children: React.ReactNode }) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error: Error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: Error, info: ErrorInfo) {
		console.error(error, info);
		// Example "componentStack":
		//   in ComponentThatThrows (created by App)
		//   in ErrorBoundary (created by App)
		//   in div (created by App)
		//   in App
		logError(error);
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return this.props.fallback;
		}

		return this.props.children;
	}
}

const messages = [
	"Oops! We're recovering this hiccup—try again soon.",
	"Uh-oh! Something went wrong. We're on it!",
	"Yikes! A glitch, but we're fixing it fast.",
	"Hang tight! We're recovering from this error.",
	'Oops! A quick fix is on the way.',
	'Something broke—working to recover it!',
	'Oops! We hit a snag. Trying again should help.',
	"Error! We're fixing it faster than a lost cart.",
	"Uh-oh! We're on the case—try again soon.",
	"Oops! Something went wrong, but we're recovering fast.",
	"Error! We're fixing it faster than a lost cart.",
];
